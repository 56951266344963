import validate from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/middleware/admin-only.js"),
  "guest-only": () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/middleware/guest-only.js"),
  "user-only": () => import("/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/middleware/user-only.js")
}