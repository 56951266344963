import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import animate_1MY2JJFgCJ554zj0WkzEgGqMxNdIOpe_c9ooicWtM1M from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/plugins/animate.js";
import intersect_LkjGwZn4SgQDvkOciYt2Vf6vedf1by80SPCrJ2ufWvk from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/plugins/intersect.js";
import plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc from "/Users/elliot/Development/Namespaces/18ZEHN/Projects/handelskontor/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  animate_1MY2JJFgCJ554zj0WkzEgGqMxNdIOpe_c9ooicWtM1M,
  intersect_LkjGwZn4SgQDvkOciYt2Vf6vedf1by80SPCrJ2ufWvk,
  plugin_client_lxWKvJVWQ7nxykTLpu__093p6rdFQhYd_ZKYo3UMtbc
]