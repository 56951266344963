<template>
  <!--  <v-app>-->
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
  <!--  </v-app>-->
  <div v-if="isDev" class="fixed bottom-0 left-0 z-[60]">
    <div class="text-xs font-bold text-white bg-slate-900 p-3">
      <span class="sm:hidden">XS</span>
      <span class="hidden sm:block md:hidden">SM</span>
      <span class="hidden md:block lg:hidden">MD</span>
      <span class="hidden lg:block xl:hidden">LG</span>
      <span class="hidden xl:block 2xl:hidden">XL</span>
      <span class="hidden 2xl:block">2XL</span>
    </div>
  </div>

  <template v-if="notification.store.value.length">
    <div
      class="fixed bottom-6 right-6 z-50 max-w-xs w-full pointer-events-none flex items-end flex-col gap-3"
    >
      <template v-for="item in notification.store.value" :key="item.id">
        <Notification v-bind="item" />
      </template>
    </div>
  </template>
</template>

<script setup>
const isDev = import.meta.dev;

const notification = useNotification();
</script>

<style>
@import url("~/assets/styles/tailwind.css");
@import url("~/assets/fonts/poppins/fontface.css");
</style>
