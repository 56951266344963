export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('intersect', {
        mounted(el, binding) {
            const options = {
                rootMargin: '0px 0px 0px 0px',
                callback: null,
            };

            if (typeof binding.value === 'object') {
                options.threshold = binding.value.threshold ?? options.threshold;
                options.callback = binding.value.callback ?? null;
            } else if (typeof binding.value === 'function') {
                options.callback = binding.value;
            }

            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        if (options.callback) {
                            options.callback(entry);
                        }

                        observer.unobserve(el);
                    }
                },
                {
                    rootMargin: options.rootMargin,
                }
            );

            observer.observe(el);
        },

        getSSRProps() {
            return {};
        },
    });
});
